import axios from "axios";

const state = () => ({
    all: [],
});

const actions = {
    async getHistory({commit}) {
        let resp = await axios.get('/data/history.json');
        let data = resp.data;

        commit('SET_HISTORY', data);
    },
};

const mutations = {
    SET_HISTORY(state, history) {
        state.all = history;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
