<template>
    <footer class="footer bg-dagray">
        <section class="container-xxl">
            <div class="row justify-content-between">
                <div class="col-12 col-md-4 col-lg-6">
                    <a href="/" class="d-flex">
                        <img :src="require('@/assets/svg/fundLogoL.svg')"
                             class="img-fluid mb-4 mb-lg-3 me-auto ms-auto ms-lg-0">
                    </a>
                </div>
                <div class="col-12 col-md-4 col-lg-auto">
                    <div class="fs-s fw-4 clr-ligray mb-3 text-center text-lg-start">
                      121108, г. Москва, ул. Минская, д. 11
                    </div>
                    <div class="fs-s fw-4 clr-ligray mb-3 text-center text-lg-start">
                        ИНН 7730260692<br>
                        ОГРН 1207700461760
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-auto">
                    <a href="mailto:info@legalculture.ru"
                       class="fs-m clr-ligray link-jump _white text-center text-lg-start p-0 d-block mb-2 mb-lg-3">
                        <strong>info@legalculture.ru</strong>
                        <span class="fs-b clr-daligray d-none d-lg-block">
                            почта
                        </span>
                    </a>
                    <a href="tel:+74957270900"
                       class="fs-m clr-ligray link-jump _white text-center text-lg-start p-0 d-block mb-2 mb-lg-3">
                        <strong>+7 (495) 727-09-00</strong>
                        <span class="fs-b clr-daligray d-none d-lg-block">
                            телефон
                        </span>
                    </a>
                </div>
                <div class="col-12">
                    <div class="fs-s clr-daligray text-center text-lg-start mt-2 mt-lg-5">
                        © 2021 ФОНД РАЗВИТИЯ ПРАВОВОЙ КУЛЬТУРЫ И ПРАВОСОЗНАНИЯ
                    </div>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
    export default {
        name: "BaseFooterIndex"
    }
</script>

<style lang="scss" scoped>
    .footer {
        padding: 30px 0;
        @include _992 {
            padding: 40px 0;
        }
    }
</style>