import axios from "axios";

const state = () => ({
    all: [],
});

const actions = {
    async getProjects({commit}) {
        let resp = await axios.get('/data/projects.json');
        let data = resp.data;

        commit('SET_PROJECTS', data);
    },
};

const mutations = {
    SET_PROJECTS(state, projects) {
        state.all = projects;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
