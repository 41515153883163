<template>
    <header class="header">
        <nav-bar :routes="routesList"/>
    </header>
    <section class="container-xxl" v-if="this.$route.name !== 'Home'">
        <breadcrumb :breadcrumb-list="breadcrumbList"/>
    </section>
</template>

<script>
    import NavBar from "@/components/BaseHeader/NavBar";
    import {mapActions, mapGetters, mapState} from "vuex";
    import Breadcrumb from "./Breadcrumb";

    export default {
        name: "BaseHeaderIndex",
        data() {
            return {
                routesList: [],
                routesItem: {},
                breadcrumbList: [
                    {
                        name: '',
                        path: '',
                        code: '',
                    }
                ],
            }
        },
        components: {
            Breadcrumb,
            NavBar,
        },
        computed: {
            ...mapState({
                articles: state => state.articles.all,
            }),
            ...mapGetters({
                getArticleByCode: 'articles/getArticleByCode',
            }),
        },
        created() {
            this.updateRoutesList();
        },
        async mounted() {
            await this.getArticles();
            this.updateBreadcrumbList();
        },
        watch: {
            $route() {
                this.updateBreadcrumbList();
            }
        },
        methods: {
            ...mapActions({
                getArticles: 'articles/getArticles',
            }),
            updateRoutesList() {

                this.$router.options.routes.forEach(route => {

                    this.routesList.push({
                        name: route.name,
                        nav: route.meta.name,
                        path: route.path,
                    });

                    route.children.forEach(childRoute => {
                        this.routesList.push({
                            name: childRoute.name,
                            nav: childRoute.meta.name,
                            path: childRoute.path,
                        });
                    });

                });

                this.routesList.splice(2, 0, {
                    name: 'Anchor',
                    nav: 'Контакты',
                    path: `${this.routesList[0].path}#fund-contact`,
                });
            },
            updateBreadcrumbList() {
                this.breadcrumbList = [];
                this.routesItem = this.getArticleByCode(this.$route.params.code);

                this.$route.matched.forEach(route => {

                    this.breadcrumbList.push({
                        name: route.name === 'ArticleDetail' && this.routesItem
                            ? this.routesItem.name
                            : route.meta.name,
                        path: route.path,
                        code: route.name,
                    });

                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .header {
        margin-bottom: 70px;
        @include _992 {
            margin-bottom: 85px;
        }
    }
</style>