<template>
    <svg aria-hidden="true"
         style="position: absolute; width: 0; height: 0; overflow: hidden;"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <symbol id="icon-web">
                <path d="M16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.9512 17.6683 16.4141 18.932 14.59 19.56V19.56ZM14.34 14H9.66C9.56 13.34 9.5 12.68 9.5 12C9.5 11.32 9.56 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM12 19.96C11.17 18.76 10.5 17.43 10.09 16H13.91C13.5 17.43 12.83 18.76 12 19.96ZM8 8H5.08C6.03886 6.32721 7.5748 5.06149 9.4 4.44C8.8 5.55 8.35 6.75 8 8ZM5.08 16H8C8.35 17.25 8.8 18.45 9.4 19.56C7.57862 18.9317 6.04485 17.6677 5.08 16V16ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM12 4.03C12.83 5.23 13.5 6.57 13.91 8H10.09C10.5 6.57 11.17 5.23 12 4.03V4.03ZM18.92 8H15.97C15.657 6.76146 15.1936 5.5659 14.59 4.44C16.43 5.07 17.96 6.34 18.92 8ZM12 2C6.47 2 2 6.5 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V2Z"
                      fill="inherit"/>
            </symbol>
            <symbol id="icon-email">
                <path d="M18.375 3.9375H2.625C2.2769 3.9375 1.94306 4.07578 1.69692 4.32192C1.45078 4.56806 1.3125 4.9019 1.3125 5.25V15.75C1.3125 16.0981 1.45078 16.4319 1.69692 16.6781C1.94306 16.9242 2.2769 17.0625 2.625 17.0625H18.375C18.7231 17.0625 19.0569 16.9242 19.3031 16.6781C19.5492 16.4319 19.6875 16.0981 19.6875 15.75V5.25C19.6875 4.9019 19.5492 4.56806 19.3031 4.32192C19.0569 4.07578 18.7231 3.9375 18.375 3.9375ZM16.9312 5.25L10.5 9.69937L4.06875 5.25H16.9312ZM2.625 15.75V5.84719L10.1259 11.0381C10.2358 11.1143 10.3663 11.1552 10.5 11.1552C10.6337 11.1552 10.7642 11.1143 10.8741 11.0381L18.375 5.84719V15.75H2.625Z"
                      fill="inherit"/>
            </symbol>
            <symbol id="icon-phone">
                <path d="M17.0626 19.0312H16.9511C4.05577 18.2897 2.22483 7.40906 1.9689 4.08844C1.94827 3.83026 1.9788 3.57054 2.05872 3.32418C2.13864 3.07781 2.26639 2.84964 2.43465 2.65273C2.60291 2.45582 2.80836 2.29405 3.03925 2.17668C3.27013 2.05931 3.52191 1.98865 3.78015 1.96875H7.39609C7.65895 1.9685 7.91584 2.04718 8.13347 2.19461C8.3511 2.34203 8.51945 2.55141 8.61671 2.79563L9.61421 5.25C9.71025 5.48858 9.73409 5.75011 9.68275 6.00212C9.63142 6.25413 9.50718 6.4855 9.32546 6.6675L7.92765 8.07844C8.14599 9.31924 8.7402 10.463 9.62989 11.3551C10.5196 12.2471 11.6618 12.8443 12.902 13.0659L14.3261 11.655C14.5108 11.4753 14.7444 11.3539 14.9976 11.3061C15.2509 11.2583 15.5126 11.286 15.7501 11.3859L18.2242 12.3769C18.4647 12.4772 18.67 12.6469 18.8137 12.8643C18.9575 13.0816 19.0332 13.3369 19.0314 13.5975V17.0625C19.0314 17.5846 18.824 18.0854 18.4548 18.4546C18.0856 18.8238 17.5848 19.0312 17.0626 19.0312ZM3.93765 3.28125C3.7636 3.28125 3.59668 3.35039 3.47361 3.47346C3.35054 3.59653 3.2814 3.76345 3.2814 3.9375V3.99C3.58327 7.875 5.51921 17.0625 17.0233 17.7188C17.1095 17.7241 17.1959 17.7123 17.2776 17.6842C17.3592 17.656 17.4345 17.6121 17.4992 17.5547C17.5638 17.4974 17.6165 17.4279 17.6542 17.3502C17.6919 17.2725 17.7139 17.1881 17.7189 17.1019V13.5975L15.2448 12.6066L13.3614 14.4769L13.0464 14.4375C7.33702 13.7222 6.56265 8.01281 6.56265 7.95375L6.52327 7.63875L8.38702 5.75531L7.40265 3.28125H3.93765Z"
                      fill="inherit"/>
            </symbol>
            <symbol id="icon-map">
                <g clip-path="url(#clip0_3046_2134)">
                    <path d="M16.7283 12.2925C16.0078 13.7527 15.0315 15.2075 14.0333 16.5138C13.0863 17.7452 12.0737 18.9248 11 20.0475C9.92624 18.9249 8.91369 17.7452 7.96675 16.5138C6.9685 15.2075 5.99225 13.7527 5.27175 12.2925C4.543 10.8171 4.125 9.43525 4.125 8.25C4.125 6.42664 4.84933 4.67795 6.13864 3.38864C7.42795 2.09933 9.17664 1.375 11 1.375C12.8234 1.375 14.572 2.09933 15.8614 3.38864C17.1507 4.67795 17.875 6.42664 17.875 8.25C17.875 9.43525 17.4556 10.8171 16.7283 12.2925ZM11 22C11 22 19.25 14.1818 19.25 8.25C19.25 6.06196 18.3808 3.96354 16.8336 2.41637C15.2865 0.869194 13.188 0 11 0C8.81196 0 6.71354 0.869194 5.16637 2.41637C3.61919 3.96354 2.75 6.06196 2.75 8.25C2.75 14.1818 11 22 11 22Z"
                          fill="inherit"/>
                    <path d="M11 11C10.2707 11 9.57118 10.7103 9.05546 10.1945C8.53973 9.67882 8.25 8.97935 8.25 8.25C8.25 7.52065 8.53973 6.82118 9.05546 6.30546C9.57118 5.78973 10.2707 5.5 11 5.5C11.7293 5.5 12.4288 5.78973 12.9445 6.30546C13.4603 6.82118 13.75 7.52065 13.75 8.25C13.75 8.97935 13.4603 9.67882 12.9445 10.1945C12.4288 10.7103 11.7293 11 11 11ZM11 12.375C12.094 12.375 13.1432 11.9404 13.9168 11.1668C14.6904 10.3932 15.125 9.34402 15.125 8.25C15.125 7.15598 14.6904 6.10677 13.9168 5.33318C13.1432 4.5596 12.094 4.125 11 4.125C9.90598 4.125 8.85677 4.5596 8.08318 5.33318C7.3096 6.10677 6.875 7.15598 6.875 8.25C6.875 9.34402 7.3096 10.3932 8.08318 11.1668C8.85677 11.9404 9.90598 12.375 11 12.375Z"
                          fill="inherit"/>
                </g>
                <defs>
                    <clipPath id="clip0_3046_2134">
                        <rect width="22" height="22" fill="white"/>
                    </clipPath>
                </defs>
            </symbol>
            <symbol id="icon-doc">
                <path d="M22.5001 3.73682V16.6581C22.5001 17.5148 22.8388 18.3365 23.4416 18.9423C24.0444 19.5481 24.8619 19.8884 25.7144 19.8884H38.57L38.5716 20.0144V38.6242C38.5716 39.6523 38.1652 40.6383 37.4418 41.3653C36.7185 42.0922 35.7374 42.5006 34.7144 42.5006H10.2859C9.26288 42.5006 8.2818 42.0922 7.55844 41.3653C6.83509 40.6383 6.42871 39.6523 6.42871 38.6242V7.6132C6.42871 6.58512 6.83509 5.59914 7.55844 4.87218C8.2818 4.14522 9.26288 3.73682 10.2859 3.73682H22.5001ZM24.9109 4.50402V16.6581C24.9109 16.8723 24.9955 17.0777 25.1462 17.2291C25.2969 17.3806 25.5013 17.4657 25.7144 17.4657H37.7712C37.6242 17.2545 37.4591 17.0567 37.2778 16.8745L25.3351 4.87227C25.2028 4.73934 25.061 4.61627 24.9109 4.50402Z"
                      fill="#0076DE"/>
                <line x1="13.7729" y1="27.1001" x2="32.5911" y2="27.1001" stroke="white" stroke-width="3"
                      stroke-linecap="round"/>
                <line x1="13.7729" y1="31.897" x2="32.5911" y2="31.897" stroke="white" stroke-width="3"
                      stroke-linecap="round"/>
            </symbol>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: "AllSvgSybmols"
    }
</script>
