import axios from "axios";

const state = () => ({
    all: [],
});

const actions = {
    async getTeam({commit}) {
        let resp = await axios.get('/data/team.json');
        let data = resp.data;

        commit('SET_TEAM', data);
    },
};

const mutations = {
    SET_TEAM(state, team) {
        state.all = team;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
