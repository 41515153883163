<template>
  <base-header-index/>
  <main class="main">
    <router-view/>
  </main>
  <base-footer-index/>
  <all-svg-sybmols/>
  <form-modal/>
</template>

<script>

import BaseHeaderIndex from "./components/BaseHeader/Index";
import BaseFooterIndex from "./components/BaseFooter/Index";
import AllSvgSybmols from "./components/AllSvgSybmols";
import FormModal from "./components/FormModal";

export default {
  name: 'App',
  components: {
    FormModal,
    AllSvgSybmols,
    BaseFooterIndex,
    BaseHeaderIndex
  },
}
</script>
<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;

  .header {
    flex: 0 0 auto;
  }

  .main {
    flex: 1 0 auto;
  }

  .footer {
    flex: 0 0 auto;
  }
}
</style>