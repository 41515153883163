import {createStore} from 'vuex'
import history from './modules/history'
import articles from './modules/articles'
import projects from './modules/projects'
import partners from './modules/partners'
import team from './modules/team'

export default createStore({
    modules: {
        history,
        articles,
        projects,
        partners,
        team
    },
})
