<template>
  <div class="modal" tabindex="-1" ref="formModal" id="formModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="ff-m modal-title">
            Бесплатная юридическая помощь онкобольным
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"/>
        </div>
        <div class="modal-body">
          <form class="form-base row g-3" @submit.prevent="submitForm" @reset.prevent="resetForm">
            <div class="col-12">
              <label class="form-label">
                <input type="text" placeholder="Имя*" v-model.trim="name"
                       :class="['form-control', { error: v$.name.$error}]"
                       @blur="v$.name.$touch">
              </label>
            </div>
            <div class="col-12">
              <label class="form-label">
                <i-mask-component
                    v-model.trim="phoneNumber"
                    :mask="'+{7}(000)000-00-00'"
                    placeholder='Телефон*'
                    :class="['form-control', { error: v$.phoneNumber.$error }]"
                    @blur="v$.phoneNumber.$touch"
                />
              </label>
            </div>
            <div class="col-12">
              <label class="form-label">
                <input type="email" placeholder="E-mail*" v-model.trim="emailAddress"
                       :class="['form-control', { error: v$.emailAddress.$error }]"
                       @blur="v$.emailAddress.$touch">
              </label>
            </div>
            <div class="col-12">
              <div class="row align-items-center">
                <div class="col-12">
                  <base-button
                      class="ff-m text-center d-flex align-items-center mb-4"
                      :class="[{sending: submitStatus === 'PENDING'}]"
                      :color="['_back_base']"
                      :type="'submit'">
                    Подать заявку
                    <div class="loading ms-4"/>
                  </base-button>
                </div>
                <div class="col-12">
                  <div class="ff-r fs-s fw-4 text-white text-break">
                    Нажимая на кнопку «Связаться с нами» вы соглашаетесь с
                    <a href="/pdf/form/политика-конфиденциальности.pdf" target="_blank" referrerpolicy="no-referrer"
                       class="clr-dodblue">
                      политикой конфиденциальности
                    </a>
                    и даете
                    <a href="/pdf/form/согласие.pdf" target="_blank" referrerpolicy="no-referrer" class="clr-dodblue">
                      согласие на обработку персональных данных
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" tabindex="-1" ref="submitStatusModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="ff-m modal-title">{{ statusTitle }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"/>
        </div>
        <div class="modal-body">
          <p>{{ statusSubtitle }}</p>
        </div>
        <div class="modal-footer">
          <base-button :color="['_back_base']" data-bs-dismiss="modal">Закрыть</base-button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import {required, minLength, email} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import BaseButton from "./BaseButton";
import {Modal} from 'bootstrap'
import {IMaskComponent} from 'vue-imask';

export default {
  name: "FormModal",
  components: {BaseButton, IMaskComponent},
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      info: {
        result: ''
      },
      name: '',
      phoneNumber: '',
      emailAddress: '',
      formAction: './php/sendmail.php',
      submitStatus: null,
      statusTitle: 'Благодарим за обращение!',
      statusSubtitle: 'Ваше сообщение успешно отправлено. В ближайшее время с вами свяжется администратор.',
    }
  },
  validations() {
    return {
      name: {required, $lazy: true},
      phoneNumber: {required, minLength: minLength(16), $lazy: true},
      emailAddress: {required, email, $lazy: true},
    }
  },
  methods: {
    controlPhoneNumberMask(e) {
      return IMask(e.target, this.maskOptions);
    },
    showSubmitStatusModal() {
      let modal = Modal.getInstance(document.getElementById('formModal'));
      modal.hide();
      new Modal(this.$refs.submitStatusModal).show();
    },
    resetForm() {
      this.name = '';
      this.phoneNumber = '';
      this.emailAddress = '';
      this.v$.$reset();
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.submitStatus = 'ERROR';
      } else {
        this.submitStatus = 'PENDING';

        setTimeout(async (self) => {

          let formData = new FormData();

          formData.append('name', self.name);
          formData.append('phone', self.phoneNumber);
          formData.append('email', self.emailAddress);

          const response = axios.post(self.formAction, formData)

              .catch(() => {

                self.statusTitle = 'Произошла ошибка';
                self.statusSubtitle = 'Попробуйте отправить форму позже.';
                self.showSubmitStatusModal();
                self.submitStatus = '';

              });

          const respValue = await response;

          self.info = respValue.data;

          if (!!self.info.result && self.info.result === 'success') {

            self.statusTitle = 'Благодарим за обращение!';
            self.statusSubtitle = 'Ваше сообщение успешно отправлено. В ближайшее время с вами свяжется администратор.';
            self.showSubmitStatusModal();
            self.resetForm();

          } else {
            if (self.info.message) {

              self.statusTitle = 'Произошла ошибка';
              self.statusSubtitle = self.info.message;
              self.showSubmitStatusModal();

            } else {

              self.statusTitle = 'Произошла ошибка';
              self.statusSubtitle = 'Попробуйте отправить форму позже.';
              self.showSubmitStatusModal();
            }
          }

          self.submitStatus = '';

        }, 300, this)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-base {
  .form-label {
    display: block;
    width: 100%;
  }

  .form-control {
    outline: none;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #FFFFFF;
    background: #FFFFFF;
    padding: 13.5px 15px;
    font-size: $fs-medium;
    line-height: normal;

    &::placeholder {
      color: #787878;
    }

    &.error {
      border-color: #D6193A;
      color: #D6193A;

      &::placeholder {
        color: #D6193A;
      }
    }
  }

  .sending {
    pointer-events: none;
    cursor: not-allowed;

    .loading {
      transition-delay: 0.5s;
      transition-duration: 1s;
      opacity: 1;
      position: static;
    }
  }

  .loading {
    width: 20px;
    height: 20px;
    border: 2px solid $dodgerblue;
    border-left-color: transparent;
    border-right-color: transparent;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.5s;
    animation: 0.8s linear infinite rotate;
    position: absolute;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>