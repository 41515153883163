<template>
    <button :class="['button',color]" :type="type">
        <slot>Отправить</slot>
    </button>
</template>

<script>
    export default {
        name: "BaseButton",
        props: {
            color: {
                type: [String, Array, Object],
                default: 'button',
            },
            type: {
              type: [String, Array, Object],
                default: 'button',
            },
        },
    }
</script>

<style lang="scss">
    .button {
        border-radius: 0;
        padding: 10.5px 50px;
        font-size: $fs-small;

        &._outline_base {
            background: transparent;
            border: 1.5px solid $bblue;
            color: $bblue;

            &:hover,
            &:active,
            &._active {
                color: white;
                background: $bblue;
            }

            &:active {
                box-shadow: 0 4px 4px 0 #00000040 inset;
            }
        }

        &._outline_tab {
            background: transparent;
            border: 1.5px solid $darkgray;
            color: $darkgray;
            /*padding: 7px 30px;*/

            line-height: 15px;
            height: 100%;

            @include _992 {
                padding: 15px 50px 15px 20px;

                line-height: normal;;
                height: auto;
            }

            &:hover {
                color: white;
                background: $darkgray;
            }

            &:active,
            &._active {
                background: $bblue;
                border-color: $bblue;
                color: white;
            }

            &:active {
                box-shadow: 0 4px 4px 0 #00000040 inset;
            }
        }

        &._back_base {
            background: $bblue;
            border: 1.5px solid $bblue;
            color: white;
            padding: 10.5px 30px;

            &:hover,
            &:active,
            &._active {
                color: white;
                background: $hblue;
                border-color: $hblue;
            }

            &:active {
                box-shadow: 0 4px 4px 0 #00000040 inset;
            }
        }

        &._back_light {
            background: white;
            border: 1.5px solid white;
            color: $darkgray;

            &:hover,
            &:active,
            &._active {
                color: white;
                background: $bblue;
                border-color: $bblue;
            }

            &:active {
                box-shadow: 0 4px 4px 0 #00000040 inset;
            }
        }
    }
</style>