<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb" v-if="window.width <= 767">
      <li class="breadcrumb-item" v-if="breadcrumbList[breadcrumbList.length - 2]">
        <router-link :to="breadcrumbList[breadcrumbList.length - 2].path">
          {{ breadcrumbList[breadcrumbList.length - 2].name }}
        </router-link>
      </li>
    </ol>
    <ol class="breadcrumb" v-else>
      <li :class="['breadcrumb-item', {active : this.$route.name === breadcrumb.code}]"
          v-for="(breadcrumb, idx) in breadcrumbList" :key="`breadcrumb-${idx}`">

        <template v-if="idx !== breadcrumbList.length - 1">
          <router-link :to="breadcrumb.path">
            {{ breadcrumb.name }}
          </router-link>
        </template>
        <template v-else>
          {{ getMaxString(breadcrumb.name) }}
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      window: {
        width: 0,
      }
    }
  },
  props: {
    breadcrumbList: {
      type: Array,
      required: true,
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getMaxString(string) {
      return string.length <= 150
          ? string
          : (string.substr(0, 150) + '...');
    },
    handleResize() {
      this.window.width = window.innerWidth;
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  padding: 12px 0 30px 0;
  border-top: 1px solid #E3E3E8;
  @include _992 {
    padding: 18px 0 48px 0;
  }

  &-item {
    font-size: $fs-less;
    color: $dimgray;

    &:hover {
      a {
        color: $bblue;
      }
    }

    &.active {
      font-weight: 500;
    }
  }
}
</style>