<template>
  <nav class="navbar navbar-expand-lg fixed-top py-3 align-items-stretch"
       :class="{'show': this.collapsed}">
    <div class="container-xxl d-flex flex-wrap align-items-baseline">
      <div class="navbar-controller">
        <router-link class="navbar-brand" :to="routes[0].path" @click="clickNavbarItem()">
          <img :src="require('@/assets/svg/fundLodoB.svg')" class="img-fluid">
        </router-link>
        <div :class="['navbar-toggler', {'active': this.collapsed}]"
             data-bs-toggle="collapse"
             data-bs-target="#navbar"
             @click="clickNavbarToggler()">
          <div class="dash"/>
          <div class="dash"/>
          <div class="dash"/>
          <div class="dash"/>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navbar" ref="navbarCollapse">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item" v-for="route in routes" :key="route.name"
              @click="clickNavbarItem()">
            <router-link class="nav-link"
                         :class="{ active: checkNavbarItemActive(route)}"
                         :to="route.path">
              {{ route.nav }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {Collapse} from 'bootstrap'

export default {
  name: "HeaderNavBar",
  props: {
    routes: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      collapsed: false,
      window: {
        width: 0,
      }
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    },
    clickNavbarToggler() {
      this.collapsed = !this.collapsed;

      if (this.collapsed) {
        document.querySelector('html').style.overflowY = 'hidden';
      } else {
        document.querySelector('html').style.overflowY = 'auto';
      }
    },
    clickNavbarItem() {
      if (this.window.width < 992) {
        this.clickNavbarToggler();
        new Collapse(this.$refs.navbarCollapse, {hide: true});
      }
    },
    checkNavbarItemActive(route) {
      let active = this.$route.name === route.name;

      if (route.name === 'Articles' && this.$route.name === 'ArticleDetail') active = true;

      if (route.name === 'Anchor' && this.$route.hash === '#fund-contact') active = true;

      return active;
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  background: white;

  .navbar-controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include _992 {
      width: auto;
    }
  }

  &.show {
    background: $darkgray;
    min-height: 100vh;

    .navbar-brand {
      filter: invert(1) brightness(3);
    }

    .navbar-toggler {
      .dash {
        background: white;
      }
    }
  }

  .navbar-brand {
    padding: 0;
    max-width: 150px;
    @include _992 {
      max-width: none;
    }
  }

  .navbar-toggler {
    display: flex;
    padding: 0;
    flex-direction: column;
    justify-content: space-between;
    height: 17px;
    position: relative;

    @include _992 {
      display: none;
      position: absolute;
    }

    .dash {
      width: 25px;
      height: 3px;
      background: $darkgray;
      transition: 0.25s;

      &:nth-of-type(3) {
        position: absolute;
        top: calc(50% - 1.5px);
      }
    }

    &.active {
      .dash {
        &:nth-of-type(1) {
          opacity: 0;
        }

        &:nth-of-type(4) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: rotate(45deg);
        }

        &:nth-of-type(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .navbar-nav {
    .nav-item {
      margin: 0 auto 30px auto;

      @include _992 {
        margin: auto 0 auto 40px;
      }

      .nav-link {
        color: white;
        font-size: $fs-base;
        font-family: $ff-montserrat;
        font-weight: 600;
        text-transform: uppercase;
        padding: 0;

        @include _992 {
          color: $darkgray;
        }

        &.active {
          color: $bblue;
        }
      }
    }
  }
}

</style>