import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home-page" */ '../views/'),
        meta: {
            name: 'Главная',
        },
        children: [
            {
                path: '/about',
                name: 'About',
                component: () => import(/* webpackChunkName: "about-page" */ '../views/about/'),
                meta: {
                    name: 'О фонде',
                },
            },
            {
                path: '/articles',
                name: 'Articles',
                component: () => import(/* webpackChunkName: "articles-page" */ '../views/articles/'),
                meta: {
                    name: 'Новости',
                },
                children: [
                    {
                        path: ':code',
                        name: 'ArticleDetail',
                        component: () => import(/* webpackChunkName: "article-page" */ '../views/articles/Detail'),
                        meta: {
                            name: 'Новость',
                        },
                    },
                ],
            },
            {
                path: '/partnership',
                name: 'Partnership',
                component: () => import(/* webpackChunkName: "partnership-page" */ '../views/partnership/'),
                meta: {
                    name: 'Сотрудничество',
                },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {el: to.hash};
        }
        return {top: 0};
    }
});

export default router
