import axios from "axios";

const state = () => ({
    all: [],
});

const actions = {
    async getPartners({commit}) {
        let resp = await axios.get('/data/partners.json');
        let data = resp.data;

        commit('SET_PARTNERS', data);
    },
};

const mutations = {
    SET_PARTNERS(state, partners) {
        state.all = partners;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
