import axios from "axios";

const state = () => ({
    all: [],
});

const actions = {
    async getArticles({commit}) {
        let resp = await axios.get('/data/articles.json');
        let data = resp.data;

        commit('SET_ARTICLES', data);
    },
};

const mutations = {
    SET_ARTICLES(state, data) {
        state.all = data;
    },
};

const getters = {
    getArticleById: state => id => {
        return state.all.find(article => parseInt(article.id) === parseInt(id));
    },
    getArticleByCode: state => code => {
        return state.all.find(article => article.code === code);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
